<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Cancelar Todos</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>        
        <div class="vx-row">
            <div class="vx-col w-1/3">
                <label class="vs-input--label">Nome</label>
                <vs-input class="w-full" v-model="data_local.nomeLote" :maxlength="255"/>   
            </div>     
            <div class="vx-col w-1/3">
                <label class="vs-input--label">Data/Hora</label>
                <p class="w-full mt-3 font-bold">  {{data_local.dataHoraCriacaoFormatada}} </p>
            </div>   
            <div class="vx-col w-1/3">
                <label class="vs-input--label">Usuario</label>
                <p class="w-full mt-3 font-bold">  {{data_local.usuario.nome}} </p>
            </div>   
        </div>     
        <div class="vx-row mt-2">
            <div class="vx-col w-1/5">
                <label class="vs-input--label">Pendentes</label>
                <p class="w-full mt-1 font-bold">  {{data_local.qtdTotalComandosPendentes}} </p>
            </div>     
            <div class="vx-col w-1/5">
                <label class="vs-input--label">Enviados</label>
                <p class="w-full mt-1 font-bold">  {{data_local.qtdTotalComandosEnviados}} </p>
            </div>     
            <div class="vx-col w-1/5">
                <label class="vs-input--label">Executados</label>
                <p class="w-full mt-1 font-bold">  {{data_local.qtdTotalComandosExecutados}} </p>
            </div>     
            <div class="vx-col w-1/5">
                <label class="vs-input--label">Cancelados</label>
                <p class="w-full mt-1 font-bold">  {{data_local.qtdTotalComandosCancelados}} </p>
            </div>     
            <div class="vx-col w-1/5">
                <label class="vs-input--label">TOTAL</label>
                <p class="mt-1 font-bold">  {{data_local.qtdTotalComandos}} </p>
            </div>     
        </div>
        <div class="flex">
            <vs-button size="small" class="mt-4" icon="refresh" color="primary" @click="recarregarDados()">ATUALIZAR LISTA</vs-button>
            <vs-button size="small" class="mt-4 ml-2" icon="file_download" color="primary" @click="exportToCsv()">EXPORTAR</vs-button>
        </div>
        <div id="div-with-loading" class="vs-con-loading__container">
            <vs-table :data="listaComandosEnviados" class="tabelaComScroll mt-2" noDataText="Nenhum comando enviado" stripe hoverFlat >
                <template slot="thead">
                    <vs-th ></vs-th>
                    <vs-th sort-key="serialModulo">Serial</vs-th>
                    <vs-th sort-key="comandoEnviado">Comando</vs-th>            
                    <vs-th >Parâmetro</vs-th>            
                    <vs-th sort-key="status">Status</vs-th>            
                    <vs-th >Resposta</vs-th>            
                    <vs-th >Cadastro / Envio / Execução</vs-th> 
                    <vs-th >Usuário</vs-th>
                    <vs-th sort-key="qtdTentativas">N.</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td> 
                            <div class="flex"> 
                                {{indextr+1}}
                                <vs-button size="small" class="ml-2" icon="delete" color="danger" @click="cancelarComando(tr, true)" />
                                <vs-button size="small" class="ml-2" icon="refresh" color="primary" @click="reenviarComando(tr, true)" />                                
                            </div>
                        </vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.serialModulo}}</small></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.comandoEnviado}}</small></vs-td>                
                        <vs-td style="max-width: 150px"><vs-textarea style='word-wrap: break-word; font-family: Verdana, sans-serif; font-size: 10px' v-model="tr.valorParametro"/> </vs-td>                
                        <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.status}}</small></vs-td>                
                        <vs-td style="max-width: 150px"><vs-textarea style='word-wrap: break-word; font-family: Verdana, sans-serif; font-size: 10px' v-model="tr.respostaComando"/></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.dataHoraCadastro}}<br/>{{tr.dataHoraEnvio}}<br/>{{tr.dataHoraResposta}}</small></vs-td>                                                               
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.usuarioSolicitante}}</small></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.qtdTentativas}}</small></vs-td>                
                    
                    </vs-tr>
                </template>
            </vs-table>  
        </div>
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"

const URL_SERVLET = "/rest/LoteComandoModulo";
const NAME_ROUTE_SEARCH = "backend-lote-comando-modulo-pesquisar";

export default {     
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return { nomeLote: null }
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)), 
        listaComandosEnviados: [],          
    }
  },    
  mounted() {
    if (this.registroOriginal.hasOwnProperty('id')) {                  
        this.findById();
        this.fetchListaComandos();
    }
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id");       
    },    
  },
  methods: { 
   formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {    
          return v[j]
        }))
   },
   exportToCsv() {        
        import('@/vendor/Export2Excel').then(excel => {
          const list = this.listaComandosEnviados;
          const data = this.formatJson(["serialModulo", "comandoEnviado", "valorParametro", "status", "respostaComando", "dataHoraCadastro", "dataHoraEnvio", "dataHoraResposta", "usuarioSolicitante", "qtdTentativas"], list)
          excel.export_json_to_excel({
            header: ["Serial", "Comando", "Parâmetro", "Status", "Resposta", "Cadastro", "Envio", "Execução", "Usuário", "N"],
            data,
            filename: "comandosEnviados.csv",
            autoWidth: true,
            bookType: "xlsx"
          })        
        })
    },
    esperar(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    recarregarDados(){
        this.findById();
        this.fetchListaComandos();
    },
    findById() {
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);           
                
        axios.post(URL_SERVLET + "/Recuperar", params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });                       
            } else {                
                 this.data_local = response.data.LoteComandoModulo;                 
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },  
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja cancelar os comandos PENDENTES ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    async excluirRequest() {
        this.$vs.loading();  
        for (let i = 0; i < this.listaComandosEnviados.length; i++) {
            if (this.listaComandosEnviados[i].status  !== 'CANCELADO') {
                this.cancelarComando(this.listaComandosEnviados[i], false);
            }
           await this.esperar(500);
        }
        this.$vs.loading.close();
        this.findById();
        this.fetchListaComandos();          
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('id', this.data_local.id);               
            params.append('nomeLote', this.data_local.nomeLote);      
            
            axios.post(URL_SERVLET+'/Gravar', params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
               if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/login' });
                } else {                    
                    if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.nomeLote) {
            this.errorsValidacao.push("Nome obrigatório");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    fetchListaComandos() {     
            this.$vs.loading({ container: '#div-with-loading', scale: 0.6 })
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idLote', this.data_local.id);       
            params.append('semLimite', true);  

            axios.post("/ListarComandosEnviadosModuloTCP", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close('#div-with-loading > .con-vs-loading');
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                   this.listaComandosEnviados = response.data.lista;                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
    },
    cancelarComando(tr, isAtualizar) {
        if (isAtualizar) {     
            this.$vs.loading()
        } 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        params.append('idComando', tr.idComando);        
        params.append('codigoModulo', tr.codigoModulo);
        params.append('serialModulo', tr.serialModulo);        

        axios.post("/CancelarComandoTCPModulo", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                if (response.data.statusCodigo === 0) {
                    if (isAtualizar) {
                        this.findById();
                        this.fetchListaComandos();
                    }
                } else {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
    },
    reenviarComando(tr, isAtualizar) {     
        if (isAtualizar) {
            this.$vs.loading()
        } 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        params.append('idComando', tr.idComando);
        params.append('idLote', this.data_local.id);        
        params.append('codigoModulo', tr.codigoModulo);
        params.append('serialModulo', tr.serialModulo);
        
        axios.post("/ReenviarComandoTCPModulo", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                if (response.data.statusCodigo === 0) {
                    if (isAtualizar) {
                        this.findById();
                        this.fetchListaComandos();
                    }
                } else {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
    }    
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>